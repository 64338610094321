<template>
  <v-dialog
      v-bind:value="value"
      v-on:input="onInput"
      persistent
      max-width="500"
  >
    <v-card style="opacity: 1 !important;">
      <v-toolbar color="grey darken-3" dark elevation="1">
        <v-toolbar-title>
          Оберіть тип будинку
        </v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-4 py-4">
        <v-btn depressed tile large block color="grey lighten-4" class="mt-3 mb-4" @click.stop="closeModal(1)">
          Багатоквартирний будинок
        </v-btn>
        <v-btn depressed tile large block color="grey lighten-4" class="mt-3 mb-3" @click.stop="closeModal(2)">
          Приватний будинок
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DesktopWorkPlaceCreateFlat",
  props: ['value'],
  data() {
    return {
      modalLocal: false
    }
  },
  methods: {
    onInput(e) {
      this.modalLocal = e
      this.$emit('input', e)
    },
    closeModal(e) {
      this.modalLocal = false
      this.$emit('input', false)
      if (e.target) {
        this.$emit('chosenBuildingType', 0)
      } else {
        this.$emit('chosenBuildingType', e)
      }
    }
  }
}
</script>

<style scoped>

</style>