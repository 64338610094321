import { render, staticRenderFns } from "./DesktopWorkPlaceCreateFlat.vue?vue&type=template&id=420ebaf7&scoped=true&"
import script from "./DesktopWorkPlaceCreateFlat.vue?vue&type=script&lang=js&"
export * from "./DesktopWorkPlaceCreateFlat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "420ebaf7",
  null
  
)

export default component.exports